<template>
	<div class="container">
		<el-form :model="ruleForm" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>商家地址</span>
				</div>
				<div class="content">
					<el-form-item
						label="收货人："
						:inline-message="true"
						prop="receiverName"
						:rules="[{ required: true, message: '收货人不能为空', trigger: 'blur'}]"
					>
						<el-input
							v-model="ruleForm.receiverName"
							style="width: 300px;"
							placeholder="最多输入20个字"
							maxlength="20"
						></el-input>
					</el-form-item>
					<el-form-item
						label="收货人手机号："
						:inline-message="true"
						prop="receiverPhone"
						:rules="[{ required: true, message: '收货人手机号不能为空', trigger: 'blur'}]"
					>
						<el-input
							placeholder="请输入手机号"
							style="width: 300px;"
							v-model="ruleForm.receiverPhone"
							:show-word-limit="true"
						></el-input>
					</el-form-item>

					<el-form-item
						label="收货地址："
						:inline-message="true"
						prop="address"
						:rules="[{ required: true, message: '收货人手机号不能为空', trigger: 'blur'}]"
					>
						<el-cascader
							v-model="ruleForm.address"
							:options="addressList"
							ref="cascader"
							:props="{ value: 'id' ,label:'name'}"
						></el-cascader>
					</el-form-item>

					<el-form-item
						label="详细地址："
						:inline-message="true"
						prop="detailAddress"
						:rules="[{ required: true, message: '详细地址不能为空', trigger: 'blur'}]"
					>
						<el-input
							type="textarea"
							:rows="2"
							placeholder="请输入内容,最多输入300字"
							:max="300"
							v-model="ruleForm.detailAddress"
							:show-word-limit="true"
						></el-input>
					</el-form-item>
					<el-form-item label="邮政编码：" :inline-message="true" prop="postalCode">
						<el-input
							placeholder="请输入邮政编码"
							style="width: 300px;"
							v-model="ruleForm.postalCode"
							:show-word-limit="true"
						></el-input>
					</el-form-item>
					<el-form-item label="是否默认：" :inline-message="true" prop="detailAddress">
						<el-radio v-model="ruleForm.isDefault" :label="1">是</el-radio>
						<el-radio v-model="ruleForm.isDefault" :label="0">否</el-radio>
					</el-form-item>
				</div>
			</el-card>
		</el-form>

		<div class="box-card footer">
			<el-button style="width:240px;" @click="cancelSupiler()">取消</el-button>
			<el-button
				style="width:240px;"
				type="primary"
				@click="saveSupiler('ruleForm')"
				:loading="loading"
			>保存</el-button>
		</div>
	</div>
</template>

<script>
import {
	addressAdd,
	addressEdit,
	addressFind
} from "@/api/TurnTomySelf.js";
import util from '@/utils/util.js'

export default {
	data () {
		return {
			addressList: [],
			loading: false,
			ruleForm: {
				id: '',
				receiverName: '',    //收货人
				receiverPhone: '',    //收货人电话
				detailAddress: '',   //详细地址
				postalCode: '', //邮政编码
				address: [], //地址
				isDefault: 0,    //是否是默认
				addressCode: '', //地址编码
			},

		};
	},
	computed: {

	},
	created () {
		this.ruleForm.id = this.$route.query.id || null;
		if (this.$route.query.id) {
			this.addressFind();
		}
	},
	beforeMount () {
		this.addressList = util.getAddressList();

	},
	methods: {
		//获取详情
		async addressFind () {
			let res = await addressFind({ id: this.ruleForm.id });
			if (res.success) {
				this.ruleForm = {
					id: this.$route.query.id,
					receiverName: res.data.receiverName,    //收货人
					receiverPhone: res.data.receiverPhone,    //收货人电话
					detailAddress: res.data.detailAddress,   //详细地址
					postalCode: res.data.postalCode, //邮政编码
					address: [res.data.addressCode.substring(0, 2) + '0000', res.data.addressCode.substring(0, 4) + '00', res.data.addressCode], //地址
					isDefault: res.data.isDefault,    //是否是默认
				}
			}
		},
		//取消保存
		cancelSupiler () {
			this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
				(item) => {
					return item.path != this.$route.path;
				}
			);
			this.$router.push({
				path: "/setting/businessAddress/index",
			});
		},
		// 保存
		saveSupiler (formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.sureSave();
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		// 数组去重
		unique (arr) {
			const res = new Map();
			return arr.filter((arr) => !res.has(arr) && res.set(arr, 1));
		},
		async sureSave () {
			this.loading = true
			let params = {
				...this.ruleForm
			}
			let select = this.$refs.cascader.getCheckedNodes();
			params.province = select[0].parent.parent.label;
			params.city = select[0].parent.label;
			params.area = select[0].label;
			params.addressCode = params.address[2];
			let res = '';
			if (this.ruleForm.id) {
				res = await addressEdit(params);
			} else {
				res = await addressAdd(params);
			}

			if (res.success) {
				this.cancelSupiler();
			}
			this.loading = false;
		},

	},
};
</script>

<style lang="less" scoped>
.container {
	// padding: 10px;
	width: 100%;
	overflow: hidden;

	/deep/.el-textarea {
		width: 500px;
	}
}

.upload-avatar {
	width: 145px;
	height: 145px;
	object-fit: contain;
}

.footer {
	margin-left: -10px;
	background-color: #fff;
	z-index: 999;
	width: 100%;
	margin-right: 10px;
	height: 80px;
	text-align: center;
	line-height: 80px;
	position: fixed;
	bottom: 0px;
}

.table-box {
	overflow: hidden;
	margin-top: 20px;
}

.search {
	display: flex;
}
</style>
